.header {
    width: 100%;
    height: 35px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.header__button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header__button:hover {
    background: #efefef;
}
